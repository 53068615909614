<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h1 class="s_elem-has-icon-left a_fill_default">
						Instellingen
						<icon :icon-id="'icon_cogwheel'"></icon>
					</h1>
				</div>
			</header>
			<div class="pg_settings_tabs_container">
				<template v-for="item of navigationItems">
					<template v-if="(item.requiresAdmin && userInfo.admin) || !item.requiresAdmin">
						<router-link :key="item.label" :to="{ name: item.routeName }" class="pg_tab_btn">{{
							item.label
						}}</router-link>
					</template>
				</template>
			</div>

			<router-view></router-view>
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
	data() {
		return {
			navigationItems: [
				{
					label: "Targets",
					routeName: "settings/targets",
					requiresAdmin: true,
				},
				{
					label: "Coaches",
					routeName: "settings/coaches",
					requiresAdmin: false,
				},
			],
		};
	},
	computed: {
		...mapState("auth", ["userInfo"]),
	},
	mounted() {
		this.closeUserMenu();
	},
	methods: {
		...mapActions("userMenu", ["closeUserMenu"]),
	},
};
</script>
<style lang="scss">
.pg_setting_container {
	max-width: 900px;
	padding: calc($default-padding / 2);

	.pg_setting_controls_container {
		display: flex;
		padding: 0 0 1rem 0;
		align-items: center;
		justify-content: space-between;
	}

	.pg_branches {
		flex: 1 0 50%;
		padding: 0 1.25rem 0 0.75rem;

		select {
			max-width: 200px;
			margin: 0;
		}
	}

	.pg_table {
		width: 100%;
		table-layout: fixed;

		th {
			text-align: left;
		}
	}
}
</style>
<style lang="scss" scoped>
.pg_settings_content {
	padding: $default-padding;
}

.pg_settings_tabs_container {
	position: relative;
	padding: $default-padding 0;
	margin-bottom: calc($default-padding / 2);

	&::after {
		display: block;
		content: "";
		width: 100%;
		margin-top: -1px;
		border-bottom: solid 1px rgba($brand-primary, 0.15);
		box-shadow: 0px -2px 7px rgba($brand-primary, 0.15);
	}
}

.pg_tab_btn {
	display: inline-flex;
	padding: $button-padding;
	border-radius: $default-border-radius;
	border: solid 1px rgba($brand-primary, 0.15);
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	position: relative;
	margin-right: calc($default-padding / 2);

	&.s_active {
		z-index: 1000;
		background-color: $brand-lightest-gray;
	}
}
</style>
